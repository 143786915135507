<template>
  <div>
    <v-container v-if="screenType === 'desktop'">
      <div v-html="help" style="text-align: left"></div>
    </v-container>
    <div v-else-if="user && screenType !== 'desktop'">
      <div>
        <v-row>
          <v-col cols="2">
            <v-row>
              <v-col cols="12">
                <img
                    class="mt-6 ml-2"
                    alt="logo"
                    @click="menuHandler = !menuHandler"
                    style="cursor: pointer;width: 28px;height: 20px;"
                    src="@/assets/menu-mobile.png"
                >
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="10">
            <v-row>
              <v-col cols="12" class="text-left" style="padding-left: 20vw">
                <img
                    class="mt-1"
                    alt="logo"
                    @click="$router.push('/')"
                    style="cursor: pointer;width: 135px;height: 78px;"
                    src="@/assets/BestAid.svg"
                >
              </v-col>
              <v-col cols="4">
                <v-btn
                    class=" text-capitalize"
                    color="#39bf88"
                    rounded
                    width="100px"
                    height="33px"
                    to="/plans"
                    elevation="0"
                    style="font-family: Blinker-Regular;color:white;font-size: 14px!important;"
                >Buy
                </v-btn>
              </v-col>
              <v-col cols="8" class="text-left">
                <v-btn
                    height="4vh"
                    text
                    style="color:#ffffff;font-size:1vw!important"
                    class="text-capitalize"
                >
                  <img class="mr-1" src="@/assets/icons/user-icon.svg" alt="avatar"
                       style="height:8.7vw;width:8.7vw;
                       border-radius:200px;background-color:#d3d3d3;
                       border-style:solid;border-color:#c4c4c4;
                       border-width:2px;">
                  <span style="color:#6d6d6d;font-size:3.5vw">
                                      {{ user['first_name'] + ' ' + user['last_name'] }}
                  </span>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mx-0 mt-1 pa-0">
          <v-col cols="12" class="ma-0 pa-0">
            <v-divider style="background-color: #868688!important"></v-divider>
            <v-divider style="background-color: #868688!important"></v-divider>
            <v-divider style="background-color: #868688!important"></v-divider>
          </v-col>
        </v-row>
      </div>
      <div>
        <mobile-menu @closeMenu="menuHandler = false" v-if="menuHandler"></mobile-menu>
      </div>
      <div class="mt-6">
        <v-row>
          <v-col cols="12">
            <v-btn @click="$router.push('/my-page')" style="border-radius: 13px" class="text-capitalize" elevation="1" height="7vh" width="60%" color="#f2f3f7">
              <v-row>
                <v-col cols="3">
                  <v-icon large class="mobile-menu-color" color="#a8a8a8">mdi-home</v-icon>
                </v-col>
                <v-col cols="9" class="mobile-menu-color pt-5">
                  My Page
                </v-col>
              </v-row>
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-btn to="/books" style="border-radius: 13px" class="text-capitalize" elevation="1" height="7vh" width="60%" color="#f2f3f7">
              <v-row>
                <v-col cols="3">
                  <v-icon large class="mobile-menu-color" color="#34c288">mdi-bookshelf</v-icon>
                </v-col>
                <v-col cols="9" class="mobile-menu-color pt-5">
                  Book Library
                </v-col>
              </v-row>
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-btn to="/animations" style="border-radius: 13px" class="text-capitalize" elevation="1" height="7vh" width="60%" color="#f2f3f7">
              <v-row>
                <v-col cols="3">
                  <v-icon large class="mobile-menu-color" color="#ffc16e">mdi-animation</v-icon>
                </v-col>
                <v-col cols="9" class="mobile-menu-color pt-5">
                  Animation Library
                </v-col>
              </v-row>
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-btn to="/videos" style="border-radius: 13px" class="text-capitalize" elevation="1" height="7vh" width="60%" color="#f2f3f7">
              <v-row>
                <v-col cols="3">
                  <v-icon large class="mobile-menu-color" color="#ff8f4e">mdi-video</v-icon>
                </v-col>
                <v-col cols="9" class="mobile-menu-color pt-5">
                  Video Library
                </v-col>
              </v-row>
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-btn to="/questions/new" style="border-radius: 13px" class="text-capitalize" elevation="1" height="7vh" width="60%" color="#f2f3f7">
              <v-row>
                <v-col cols="3">
                  <v-icon large class="mobile-menu-color" color="#34c288">mdi-help</v-icon>
                </v-col>
                <v-col cols="9" class="mobile-menu-color pt-5">
                  Question Bank
                </v-col>
              </v-row>
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-btn @click="$router.push('/live-review-list')" style="border-radius: 13px" class="text-capitalize" elevation="1" height="7vh" width="60%" color="#f2f3f7">
              <v-row>
                <v-col cols="4" class="pl-2">
                  <v-img src="@/assets/icons/LiveReviewMenu.svg"
                         alt="Live Review" height="80" width="80"></v-img>
                </v-col>
                <v-col cols="8" class="mobile-menu-color pt-10">
                  Live Review
                </v-col>
              </v-row>
            </v-btn>
          </v-col>
          <v-col cols="12" v-html="help">

          </v-col>
        </v-row>
      </div>
    </div>
  </div>

</template>

<script>
import MobileMenu from "../components/mobile/menu/MobileMenu.vue";

export default {
  name: "MyPage",
  components: {MobileMenu},
  data() {
    return {
      screenType: null,
      user: null,
      help: null,
      menuHandler: false
    }
  },
  methods: {
    getScreenType() {
      this.screenType = window.screen.width < 500 ? 'mobile' : 'desktop'
    },
  },
  mounted() {
    this.getScreenType()
    this.user = this.$store.state.user
    window.axios
        .get('api/my-page')
        .then((res) => {
          this.help = res.data.data
        })

  }
}
</script>

<style scoped>
.mobile-menu-color{
  font-family: Blinker-Bold!important;
  font-size: 17px;
  color: #a8a8a8;
}
</style>